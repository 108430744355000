const routes = [
    {
        path: '/ss/admin/login',
        name: 'login',
        component: () => import('../views/admin/login'),
        meta: {
            redirectAuth : true
        },
    },
    {
        path: '/ss/admin/dashboard',
        name: 'dashboard',
        component: () => import('../views/admin/dashboard'),
        meta : {
            requireAuth: true,
        }
    },
    {
        path: '/ss/admin/payment/simulation',
        name: 'payment.simulation',
        component: () => import('../views/admin/payment/simulation'),
        meta : {
            requireAuth: true,
            roleAllowed: ['internal_ss', 'sales_ss_dev']
        }
    },

    {
        path: '/ss/admin/password',
        name: 'admin.password',
        component: () => import('../views/admin/change-password'),
        meta : {
            requireAuth: true
        }
    },

    {
        path: '/ss/admin/payment/billings',
        name: 'payment.billings',
        component: () => import('../views/admin/payment/billings'),
        meta : {
            requireAuth: true,
            roleAllowed: ['internal_ss', 'sales_ss_dev']
        }
    },
];

export default routes